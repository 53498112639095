<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: number
    color?: 'rainbow' | 'black' | 'white' | 'yellow'
    variation?: 'default' | 'dashed'
    darkMode?: boolean | 'auto'
  }>(),
  {
    size: 48,
    color: 'rainbow',
    variation: 'default',
    darkMode: 'auto',
  },
)

const style = computed(() => {
  if (!props.size) return undefined
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
  }
})

const strokeWidth = computed(() => {
  if (!props.size) return 2
  if (props.size < 30) {
    return 4
  }
  return 2
})
</script>

<script lang="ts">
export default {}
</script>

<template>
  <div :class="['loader', `loader-${variation}`, `color-${color}`]" :style="style">
    <svg v-if="variation === 'default'" class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" :stroke-width="strokeWidth" stroke-miterlimit="10" />
    </svg>
    <svg
      v-else
      :class="[
        'fill-current',
        {
          'text-loading-spinner-dark dark:text-white-100': darkMode === 'auto',
          'text-white-100': darkMode === true,
          'text-loading-spinner-dark': darkMode === false,
        },
      ]"
      :width="`${size}px`"
      :height="`${size}px`"
      viewBox="22 22 56 56"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="rotate(0 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.875s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(45 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.75s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.625s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(135 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.5s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.375s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(225 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.25s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.125s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(315 50 50)">
        <rect x="47" y="27" rx="3" ry="3.36" width="6" height="14">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
@import '@@/styles/3/modules/all';

// Jan 2021: Copied styles from app/javascript/styles/3/partials/_loader.scss with addition to color for extra color variation for loader

$green: $green-color;
$blue: $blue-color;
$red: $primary-color;
$yellow: $highlight-color;
$white: $ultralight-grey;

.color-rainbow {
  --first: #{$primary-color};
  --second: #{$blue-color};
  --third: #{$green-color};
  --forth: #{$highlight-color};
}

.color-white {
  --first: #{$white-color};
  --second: #{$white-color};
  --third: #{$white-color};
  --forth: #{$white-color};
}

.color-black {
  --first: black;
  --second: black;
  --third: black;
  --forth: black;
}

.color-yellow {
  --first: #{$yellow};
  --second: #{$yellow};
  --third: #{$yellow};
  --forth: #{$yellow};
}

// --START copied styles (with changes)
// scaling... any units
$width: 48px;
$small-width: 32px;

.loader {
  position: relative;
  margin: 0px auto;
  width: $width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  &.loader-dashed:before {
    content: none;
  }
}

.loader.is-small,
.loader[data-size='small'] {
  width: $small-width;
}

.loader[data-size='24'] {
  width: 24px;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: var(--first);
  }
  40% {
    stroke: var(--second);
  }
  66% {
    stroke: var(--third);
  }
  80%,
  90% {
    stroke: var(--forth);
  }
}
// --END copied styles (with changes)
</style>
